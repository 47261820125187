import dataApi from "@/api/apply.js";
import { getQueryVariable } from '@/util/tools.js'
export const wxLogin = {
	mounted() {
		dataApi.getWxInfo().then(res=>{  //获取微信授权信息
			if(res?.data?.id){
				this.wxAuth = true
			}else{
				this.wxAuth = false
			}
		}),
		dataApi.getUserInfo().then(res => { //拉去用户信息
			const {
				name,
				avatar,
				apply
			} = res.data
			if (getQueryVariable('code')) {
				window.location.href = window.location.origin
				// window.history.replaceState({}, document.title, "/poetry/");
			}
			this.$nextTick(() => {
				this.checkApply()
				localStorage.setItem('name', JSON.stringify(name))
			})
		}).catch(error => {
			if (error.data.code == 401) { //未登录状态
				if (!getQueryVariable('code')) { // 区分是未登录且未授权  还是  未登录已授权之后的回调
					let urlRouter = window.location.href.split('#/')[0] // 当前路由
					dataApi.getAuth({
						redirectUrl: urlRouter
					}).then(res => {
						window.location.replace(res.data.url)
					})
				} else {
					let code = getQueryVariable('code')
					let state = getQueryVariable('state')
					dataApi.login({ //登录 （ 后台需要 ）
						code: code,
						state: state
					}).then(res => {
						this.checkApply()
						if (getQueryVariable('code')) {
							window.location.href = window.location.origin
							// window.history.replaceState({}, document.title, "/poetry/");
						}
					}).catch(Error => {
						this.$router.push('/')
					})
				}
			}
		})
	}
}